import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePhotoFromProduct,
  deleteSpecificationById,
  getProductById,
  updateProduct,
} from "../../../store/features/productSlice";
import { useParams } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { getAllSubCategory } from "../../../store/features/subCategorySlice";
import {
  getAllManufacter,
  getManufacter,
} from "../../../store/features/manufacterSlice";
import { CreateSpecificationDetails } from "../../../components";
import { getAllItemSubCategory } from "../../../store/features/itemSubCategorySlice";
import { urlConfig } from "../../../config";
import Select from "react-select";

const UpdateProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const itemSubCategory =
    useSelector((state) => state.itemsubcategory.getAllItemSubCategoryData) ||
    [];
  const subCategoryData =
    useSelector((state) => state.subcategory.getAllSubCategoryData) || [];
  const manufacturers =
    useSelector((state) => state.manufacter.getAllManufacterData) || [];
  const deletePhotoLoading = useSelector(
    (state) => state.product.deletePhotoFromProductLoading
  );
  const updateProductLoading = useSelector(
    (state) => state.product.updateProductLoading
  );
  const deleteSpecificationLoading = useSelector(
    (state) => state.product.deleteSpecificationByIdLoading
  );
  const createSpecificationLoading = useSelector(
    (state) => state.product.createSpecificationLoading
  );
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [subcategory_id, setSubcategory_id] = useState("");
  const [subcategory_slug, setSubcategory_slug] = useState("");
  const [itemsubcategory_id, setItemSubcategory_id] = useState("");
  const [itmesubcategory_slug, setItemSubcategory_slug] = useState("");
  const [price, setPrice] = useState("");
  const [warranty, setWarranty] = useState("");
  const [discount, setDiscount] = useState("");
  const [instock, setInStock] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [barcode, setBarcode] = useState("");
  const [manufacturerNumber, setManufacturerNumber] = useState("");
  const [manufacturerId, setManufacturerId] = useState("");
  const [itemPhoto, setItemPhoto] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [details, setDetails] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("name", name);
    data.append("subcategory_id", subcategory_id);
    data.append("subcategory_slug", subcategory_slug);
    data.append("itemsubcategory_id", itemsubcategory_id);
    data.append("itemsubcategory_slug", itmesubcategory_slug);
    data.append("description", description);
    data.append("price", price);
    data.append("status", status);
    data.append("instock", instock);
    data.append("warranty", warranty);
    data.append("discount", discount);
    data.append("barcode", barcode);
    data.append("manufacter_id", manufacturerId);
    data.append("SKU", manufacturerNumber);

    for (let i = 0; i < photos.length; i++) {
      data.append("files", photos[i]);
    }

    dispatch(updateProduct({ id, formData: data }))
      .unwrap()
      .then(() => {
        toast.success("Produkti u përditësua me sukses");
        setPhotos([]);
        setManufacturerId("");
        document.getElementById("photoInput").value = null;
      })
      .catch((error) => {
        console.log(error);
        toast.error("Diçka shkoi keq!");
      });
  };

  const handleOnChangeSub = (e) => {
    const { slug, id } = JSON.parse(e.target.value);
    setSubcategory_id(id);
    setSubcategory_slug(slug);
  };

  const handleOnChangeItemSub = (e) => {
    const { slug, id } = JSON.parse(e.target.value);
    setItemSubcategory_id(id);
    setItemSubcategory_slug(slug);
  };

  const handleDeletePhoto = (idPhoto) => {
    dispatch(deletePhotoFromProduct({ id, idPhoto }))
      .unwrap()
      .then(() => {
        toast.success("Foto u fshi me sukses");
      })
      .catch(() => toast.error("Diçka shkoi keq!"));
  };

  const handlePhotoChange = (e) => {
    setPhotos([...photos, ...e.target.files]);
  };

  const onDelteSpecification = (id) => {
    dispatch(deleteSpecificationById(id))
      .then(() => toast.success("Specifikimi u fshi me sukses"))
      .catch(() => toast.error("Diqka shkoi keq!"));
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getAllManufacter());
    dispatch(getAllSubCategory());
    dispatch(getAllItemSubCategory());
    dispatch(getProductById(id))
      .unwrap()
      .then((product) => {
        if (product) {
          setSubcategory_id(product.subcategory_id);
          setName(product.name);
          setPrice(product.price);
          setWarranty(product.warranty);
          setDiscount(product.discount);
          setInStock(product.inStock);
          setStatus(product.status);
          setDescription(product.description);
          setManufacturerNumber(product.SKU);
          setBarcode(product.barcode);
          setItemPhoto(JSON.parse(product.path));
          setManufacturerId(product.manufacter_id);
          setDetails(JSON.parse(product?.specifications));
        }
      })
      .catch((error) => console.log(error));
  }, [
    dispatch,
    id,
    deletePhotoLoading,
    updateProductLoading,
    deleteSpecificationLoading,
    createSpecificationLoading,
  ]);

  console.log(
    manufacturers
      ?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .find((option) => Number(option.value) === Number(manufacturerId))
  );
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
            Përditëso Produktin
          </h3>
        </div>
        <form autoComplete="off" onSubmit={onSubmit} className="mt-5">
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Emri i produktit
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Emri i produktit"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="subcategory"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Nënkategoria
            </label>
            <select
              name="subcategory"
              id="subcategory"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              onChange={handleOnChangeSub}
              value={subcategory_id}
            >
              <option value="" className="dark:text-white" disabled>
                Zgjidh njerin nga opsione
              </option>
              {subCategoryData?.map((item) => (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-5">
            <label
              htmlFor="itemsubcategory"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Nënkategoria e nënkategoris
            </label>
            <select
              name="itemsubcategory"
              id="itemsubcategory"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              onChange={handleOnChangeItemSub}
            >
              <option value="" className="dark:text-white" disabled>
                Zgjidh njerin nga opsione
              </option>
              {itemSubCategory.map((item) => (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-5">
            <label
              htmlFor="manufacturer"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Prodhuesi
            </label>
            <Select
              name="manufacturer"
              id="manufacturer"
              value={
                manufacturers
                  ?.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                  .find(
                    (option) => Number(option.value) === Number(manufacturerId)
                  ) || null
              }
              options={
                manufacturers?.map((item) => ({
                  value: item.id,
                  label: item.name,
                })) || []
              }
              onChange={(selectedOption) =>
                setManufacturerId(selectedOption.value)
              }
              classNamePrefix="react-select"
              placeholder="Zgjidh njerin nga opsione"
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="barcode"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Barkodi
            </label>
            <input
              type="text"
              name="barcode"
              id="barcode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Barkodi"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="manufacturerNumber"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              SKU
            </label>
            <input
              type="text"
              name="manufacturerNumber"
              id="manufacturerNumber"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="SKU"
              value={manufacturerNumber}
              onChange={(e) => setManufacturerNumber(e.target.value)}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="price"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Çmimi
            </label>
            <input
              type="number"
              name="price"
              id="price"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Çmimi"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="warranty"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Garancioni
            </label>
            <input
              type="number"
              name="warranty"
              id="warranty"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Garancioni"
              value={warranty}
              onChange={(e) => setWarranty(e.target.value)}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="discount"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Çmimi i zbritjes
            </label>
            <input
              type="number"
              name="discount"
              id="discount"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Çmimi i zbritjes"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="discount"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Sasia e stokut
            </label>
            <input
              type="number"
              name="instock"
              id="instock"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Sasia"
              value={instock}
              onChange={(e) => setInStock(e.target.value)}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Statusi
            </label>
            <select
              name="status"
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              defaultValue=""
            >
              <option value="" disabled>
                Zgjidh njerin nga opsionet
              </option>
              <option value="1">PO</option>
              <option value="0">JO</option>
            </select>
          </div>

          <div className="mt-5">
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Përshkrimi
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description || ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
              config={{
                height: "300px",
              }}
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="photoInput"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Përditëso Fotot
            </label>
            <input
              type="file"
              onChange={handlePhotoChange}
              id="photoInput"
              multiple
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            />
          </div>

          <div className="flex mt-5">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
            >
              Përditëso Produktin
            </button>
          </div>
        </form>

        <div className="mt-5">
          <label
            htmlFor="photoInput"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Fotot
          </label>
          <div className="flex flex-row flex-wrap space-x-2 mt-2">
            {itemPhoto &&
              itemPhoto.map((item) => {
                console.log(item);
                return (
                  <div key={item.id}>
                    <button
                      className="bg-blue-500 w-full mb-2 rounded-sm text-white"
                      onClick={() => handleDeletePhoto(item.id)}
                    >
                      Fshi Foton
                    </button>
                    <img
                      src={`${urlConfig}/${item.path}`}
                      alt="..."
                      className="w-[200px] h-[200px] object-contain"
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="mt-10">
          <button
            className="mb-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={openModal}
          >
            Shto detajet
          </button>
          <table className="w-full bg-white ">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="px-4 py-2 text-center">Emri i fushes</th>
                <th className="px-4 py-2 text-center">Kontenti</th>
                <th className="px-4 py-2 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {details?.map((item) => (
                <tr
                  key={item.specification_id}
                  className="bg-gray-100 border-b"
                >
                  <td className="px-4 py-2 text-center">
                    {item.specification_name}
                  </td>
                  <td className="px-4 py-2 text-center">
                    {item.specification_value}
                  </td>
                  <td className="px-4 py-2 text-center">
                    <button
                      onClick={() => {
                        onDelteSpecification(item.id);
                      }}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Fshij
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isOpen && (
        <CreateSpecificationDetails openModal={openModal} productId={id} />
      )}
    </div>
  );
};

export default UpdateProduct;
